<mat-toolbar class="mat-elevation-z6"
             color="primary">
  <button mat-icon-button
          (click)="opened = !opened">
    <mat-icon>menu</mat-icon>
  </button> Admin portal
</mat-toolbar>
<mat-sidenav-container class="main-sidenav-container"
                       (backdropClick)="opened = !opened">
  <mat-sidenav class="sidenav"
               [mode]="mode"
               [opened]="opened"
               [fixedInViewport]="true"
               [fixedTopGap]="layoutGap">
    <mat-nav-list>
      <a mat-list-item
        [routerLink]="['/enterprise/list']" *ngIf="isAdmin">Enterprises</a>
      <a mat-list-item
        [routerLink]="['/company/list']" *ngIf="isAdmin || allowSalesUser.accessCompanies">Companies</a>

      <a mat-list-item
        [routerLink]="['/kpi-statistics']">View KPI & statistics</a>
        <a mat-list-item [routerLink]="['/kpi-statistics-platforms']">View platforms KPI</a>
      <a mat-list-item [routerLink]="['/job-categories']" *ngIf="isAdmin">Job categories</a>
      <a mat-list-item [routerLink]="['/sales-users']" *ngIf="isAdmin">Manage Sales users</a>


      <mat-accordion *ngIf="isAdmin || allowSalesUser.accessSkillQuestions">
        <mat-expansion-panel [expanded]="initialRoute === 'global-questions'">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span>Global Questions</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-list>
            <a mat-list-item
               [routerLink]="['/global-questions/skill-questions/list']"
               routerLinkActive="active-link">
              Skill Questions
            </a>
            <a mat-list-item
               [routerLink]="['/skill-question-categories']"
               routerLinkActive="active-link">
              Skill Question Categories
            </a>
            <a mat-list-item
               [routerLink]="['/skill-question-subcategories']"
               routerLinkActive="active-link">
              Skill Question Subcategories
            </a>
          </mat-list>
        </mat-expansion-panel>
      </mat-accordion>

      <a mat-list-item
         (click)="logout()">Logout</a>
    </mat-nav-list>
  </mat-sidenav>
  <main>
    <router-outlet></router-outlet>
  </main>
</mat-sidenav-container>
<app-loader></app-loader>
