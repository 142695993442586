import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { LoggedInGuard } from './guards/loggedin.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LayoutComponent } from './modules/layout/layout.component';
import { AdminGuard } from './guards/admin.guard';
import { SalesUserGuard } from './guards/sales-user.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/company/list',
    pathMatch: 'full'
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'company',
        loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule),
        canActivate: [SalesUserGuard]
      },
      {
        path: 'kpi-statistics',
        loadChildren: () => import('./modules/kpi-statistics/kpi-statistic.module').then(m => m.KpiStatisticsModule)
      },
      {
        path: 'enterprise',
        loadChildren: () => import('./modules/enterprise/enterprise.module').then(m => m.EnterpriseModule),
        canActivate: [AdminGuard]
      },
      {
        path: 'job-categories',
        loadChildren: () => import('./modules/job-categories/job-categories.module').then(m => m.JobCategoriesModule),
        canActivate: [AdminGuard]
      },
      {
        path: 'sales-users',
        loadChildren: () => import('./modules/sales-users/sales-users.module').then(m => m.SalesUsersModule),
        canActivate: [AdminGuard]
      },
      {
        path: 'global-questions',
        loadChildren: () => import('./modules/global-questions/global-questions.module').then(m => m.GlobalQuestionsModule),
        canActivate: [SalesUserGuard]
      },
      {
        path: 'skill-question-categories',
        loadChildren: () => import('./modules/skill-question-categories/skill-categories.module').then(m => m.SkillCategoriesModule),
        canActivate: [SalesUserGuard]
      },
      {
        path: 'skill-question-subcategories',
        loadChildren: () => import('./modules/skill-question-categories/skill-categories.module').then(m => m.SkillCategoriesModule),
        canActivate: [SalesUserGuard]
      },
      {
        path: 'kpi-statistics-platforms',
        loadComponent: () =>import('./modules/kpi-statistics-platforms/kpi-statistics-platform-selection/kpi-statistics-platform-selection.component').then((m) => m.KpiStatisticsPlatformSelectionComponent),
      },
      {
        path: 'kpi-statistics-platforms/statistics',
        loadComponent: () =>import('./modules/kpi-statistics-platforms/kpi-statistics-platform.component').then((m) => m.KpiStatisticsPlatformComponent),
      }
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/two-factor-auth/two-factor-auth.module').then(m => m.TwoFactorAuthModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'page-not-found',
    loadComponent: () => import('./page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    loadComponent: () => import('./page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
